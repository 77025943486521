(function(w, d) {
  var slider = {};
  var $body = $('body');
  var isInit = false;

  var $sliderElems;

  var swiperInstances = [];

  slider.init = function(cb) {
    $sliderElems = $('.slider');

    $sliderElems.each(function() {
      var $sliderEl = $(this);

      var defaultOptions = {
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        effect: 'fade',
        centeredSlides: true,
        spaceBetween: 10,
        fadeEffect: {
          crossFade: false,
        },
        observer: true,
        updateOnWindowResize: true,
        setWrapperSize: true,
        grabCursor: true,
        autoHeight: true,

        /*autoplay: {
         delay: 5000,
         },*/

        // If we need pagination
        pagination: {
          el: $sliderEl.find('.swiper-pagination')[0],
          type: 'bullets',
          clickable: true,
        },

        // Navigation arrows
        navigation: {
          nextEl: $sliderEl.find('.swiper-button-next')[0],
          prevEl: $sliderEl.find('.swiper-button-prev')[0],
        },

        // And if we need scrollbar
        scrollbar: {
          el: $sliderEl.find('.swiper-scrollbar')[0],
        },
      };
      var sliderOptions = {};

      var currentSliderId = $sliderEl.attr('data-id').trim();
      var sliderAutoplayDelay = $sliderEl.attr('data-autoplay-delay');
      var sliderLoop = $sliderEl.attr('data-loop');
      var sliderEffect = $sliderEl.attr('data-effect');

      if (sliderAutoplayDelay) {
        sliderOptions.autoplay = {
          delay: sliderAutoplayDelay.trim(),
        };
      }

      sliderOptions.loop = false;
      if (sliderLoop && (sliderLoop.trim() === 'yes' || sliderLoop.trim() === 'true')) {
        sliderOptions.loop = true;
      }

      if (sliderEffect) {
        sliderOptions.effect = sliderEffect.trim();
      }

      var currentSliderIdNumber = $(this).attr('data-id').replace(/\D/g, '');

      if (window.sliderOptions) {
        sliderOptions = window.sliderOptions[currentSliderIdNumber];
      }

      Object.assign(defaultOptions, sliderOptions);

      var swiper = new Swiper(this.querySelector('.swiper-container'),
        defaultOptions);

      $sliderEl.find('.slider-fullscreen-toggle').on('click', function() {
        $sliderEl.toggleClass('slider-fullscreen-mode');
        setTimeout(function() {
          swiper.updateSize();
          swiper.updateSlides();
          swiper.update();
        }, 10);
      });

      swiperInstances[currentSliderId] = swiper;
    });

    isInit = true;
  };

  slider.instances = swiperInstances;
  slider.$elements = $sliderElems;

  w.slider = slider;
})(window, document);
